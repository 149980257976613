body {
	font-family: "NexaFreeLightwebfont", Helvetica, Roboto, Arial, sans-serif;
}

.bold {
	font-family: "NexaFreeBoldwebfont", Helvetica, Roboto, Arial, sans-serif;
}

.green{
	color: $primary-color;
}

.orange{
	color: $secondary-color;
}

.gray-bg{
	background-color: $medium-gray;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

	
.semi-rounded {
	@include border-radius(3px);
}
	
.rounded {
	@include border-radius(1000px);
}
	
$rounded:(
	border-radius: 5px
);

$reveal-radius: $rounded;	

@mixin transform($scale:4,$skew:20deg){
    transform:scaleY($scale) skew($skew);
   -moz-transform:scaleY($scale) skew($skew);
}

// $white: #fff;

// .off-canvas {
	// a {color: $white};}
	
// .off-canvas	{
	// a:hover :scale-color(#fefefe, $lightness:-20%);}


$white: #fff;
	
@mixin custom-links {
	 
	a {color: $white;
	&:hover {color: rgba($white, 0.8);}
	//&:active {font-weight:700}
	}
}

.off-canvas{
	@include custom-links;
}

  // Container for page content
  .off-canvas-content,
  .#{$maincontent-class} {
    min-height: 100%;
    background: $body-background;
    transition: transform $offcanvas-transition-length $offcanvas-transition-timing;
    backface-visibility: hidden;
    z-index: -990;
    padding-bottom: 0.1px; // Prevents margin collapsing, which would reveal the box shadow of the wrapper

    @if has-value($maincontent-shadow) {
      box-shadow: $maincontent-shadow;
    }
	box-shadow: none;
  }

html, body, section {
    min-height: 100% !important;
	height: 100%;
}

// body:before{
    // content:"";
    // width:100%;
    // height:100%;
    // display:block;
		// }

section {
	display: block;
}

.services{
	//border-bottom: 1px solid rgba(105,105,105,.5);
}

.top-bar-title {
	padding-bottom:0.5rem;
}

.top-bar-left{
	width: 50% !important;
	padding-left: 20%;
}

.service-component {
	margin-top: 50px;
	padding-bottom: 50px;
}

.testimonials {
	margin-top: 50px;
	//border-top: 1px solid rgba(105,105,105,.5);
	//background-color: rgba(105,105,105,.1);
}

.service-image {
	//margin-top: 50px;
	padding-top: 20px;
}

.service-header{
 height: 90vh;
}

.steps {
	background-color: #F4F4F4 !important;
}

.service-hero{
	background-color: $secondary-color;
	color: $white;
	padding-top: 1rem;
	min-height: 100vh;
	.breadcrumbs{
	li{
		color: $white;
		&:not(:last-child)::after {
    color: #fff;
		}
		a{
			color: $white;
		}
	}}
	a{
		color: $white;
		margin: 30px 0;
		text-decoration: underline;
		&:hover{
		color: scale-color($white, $lightness: -5%);
		text-decoration: underline;
	}
	}
}

.service-headline{
	padding: 30px 0;
}
// .hero {
	// text-align: center;
	// height: 800px;
	// display: block;
		// -webkit-background-size: contain;
		// -moz-background-size: contain;
		// background-size: contain;

	// @include breakpoint(small only)	
		// background: url(../img/hero-small.png) no-repeat;
		// height: 600px;
		// display: block;
			// -webkit-background-size: cover;
			// -moz-background-size: cover;
			// background-size: cover;	

// }


.headline {
	position: relative;

	text-align: center;
	display:block;
	
}

.section-headline{
		margin-top: 50px;
		margin-bottom: 50px;
}

.steps-headline{
		padding:50px 0;
}

.hero-cta {
		margin-top: 40px;
}

.image-space {
	padding-bottom: 50px;
}

.service-box {
	padding-top: 50px;

}

.service-title {
	padding-top: 20px;
	text-transform: uppercase;
	font-weight:bold;
	color: rgba(105, 105, 105, .5);

}

.service-grp-title {
	padding-top: 20px;
	text-transform: uppercase;
	font-weight:bold;
	

}

.service-grp-heading{
	text-align:right; 
	padding-top:50px
}

.separator {
	max-width: 50px;
	height: 0;
	border-right: 0;
	border-top: 0;
	border-bottom: 5px solid #cacaca;
	border-left: 0;
	margin: 1.25rem auto;
	clear: both;
	
}

.separator-long {
	height: 0;
	border-right: 0;
	border-top: 0;
	border-bottom: 5px solid #7dbd05;
	border-left: 0;
	margin: 1.25rem auto;
	clear: both;
	
}

.separator-long2 {
	height: 0;
	border-right: 0;
	border-top: 0;
	border-bottom: 5px dotted #cacaca;
	border-left: 0;
	margin: 1.25rem auto;
	clear: both;
	
}

.separator2 {
	max-width: 50px;
	height: 0;
	border-right: 0;
	border-top: 0;
	border-bottom: 5px solid #cacaca;
	border-left: 0;
	margin: 1.25rem 0;
	clear: both;
	text-align:left;
}

.separator-alert {
	max-width: 50px;
	height: 0;
	border-right: 0;
	border-top: 0;
	border-bottom: 5px solid $alert-color;
	border-left: 0;
	margin: 1.25rem 0;
	clear: both;
	text-align:left;
}

.headline-separator  {
	max-width: 200px;
	height: 0;
	border-right: 0;
	border-top: 0;
	border-bottom: 2px solid #fbae17;
	border-left: 0;
	margin: 1.25rem auto;
	clear: both;

}

.headline-separator-pp  {
	max-width: 100px;
	height: 0;
	border-right: 0;
	border-top: 0;
	border-bottom: 2px solid $light-gray;
	border-left: 0;
	margin: 1.5rem auto;
	clear: both;

}

.separator-light {
	@extend .headline-separator;
	border-bottom: 2px solid #fff;
	margin: 3rem auto;
}

.page-header{
	padding: 60px 0;
}

.service-page-header{
	padding: 90px 0 50px 0;
}

.help-cushion{
	padding: 30px 0;
}

.help-accordion-cushion{
	margin-bottom: 50px;
	border-bottom: 1px solid #eee;
}

.service-cushion{
	//margin-top: 5rem;
	padding: 2.5em;
	//text-align:center;
}

.bigtext {
	font-size: 3em;
	//text-align:center;
}

.help-hero{
	background-color: $secondary-color;
	color: $white;
	padding-top: 1rem;
	.breadcrumbs{
	li{
		color: $white;
		&:not(:last-child)::after {
    color: #fff;
		}
		a{
			color: $white;
		}
	}}
}

.hero22{
	color: $white;
	padding-top: 1rem;
	.breadcrumbs{
	li{
		color: $white;
		&:not(:last-child)::after {
    color: #fff;
		}
		a{
			color: $white;
		}
	}}
	a{
		color: $white;
		//margin: 30px 0;
		text-decoration: underline;
		&:hover{
		color: scale-color($white, $lightness: -5%);
		text-decoration: underline;
	}
	}
	.separator-light {
	@extend .headline-separator;
	max-width: 50px;
	border-bottom: 5px solid #fff;
	margin: 2rem auto;
}

}



.help-topic-box {
	padding: 20px 0 0;
	margin-bottom: 20px;

}

.help-section-header {
    padding-bottom: 9px;
    margin: 40px 0 20px;
    text-align: center;
    border-bottom: 1px solid #eee;
}

.help-cotent-item {
    padding: 0.5rem 0;
    text-align: center;
		 border: 1px solid #fff;		
    //max-height: 12rem;
    overflow: hidden;
    margin-bottom: 1rem;
		&:hover {
		  border: 1px solid #e7e7e7;
		}
}

.dropdown-pane{
	@include custom-links;

}

.blurred{
	background-color: rgba(251,174,23, 0.5);
}

.reveal{
	.close-button{
		position: absolute;
		color: #696969;
		right: 1rem;
		top: 0.5rem;
		font-size: 3em;
		line-height: 1;
		cursor: pointer;
		}
		
	input[type=text] {
		border:0px;
		box-shadow:none;
		border-bottom:solid #d8d8d8 1px;
		border-radius:0px;
		margin-bottom:0;
	}

	.input-group {
			display: table;
			width: 100%;
			margin: 2rem 0;
	}	
}


.inactive {
		 pointer-events: none;
		 cursor: default;
	}

.left-border{
    border-left: 1px solid $medium-gray;
		min-height: 400px;
}

.left-border-flex{
    border-left: 1px solid $medium-gray;

	@media screen and (max-width: 40em){
	border-left: 0px solid $medium-gray;
	}
		
}

.left-border2{
    border-left: 1px solid $medium-gray;
		min-height: 400px;
		padding-left: 3em;
		margin-bottom:2em;
}		

//FOOTER

.full-width {

  max-width: 1400px;

}



$text-color: #fff;



// footer CSS



.footer {

  background-color: #696969;
  padding: 2rem 4rem;
  text-align: center;
  color: $text-color;
  i {
    font-size: 100px;
  }
}

.footer {

  h4 {
    color: $text-color;
    font-size: 1em;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 3rem;
    margin-bottom: 10px;
		margin-left: 1.25rem;
  }
  p, a {
    font-weight: 300; 
    font-size: 1em;
    color: $text-color;
  }
	
	.lower {
		font-size: 0.8em;
		padding: 20px 0;
		//border-left: 0;
		border-top: 1px dotted scale-color($text-color, $lightness: -30%);
		border-bottom: 1px dotted scale-color($text-color, $lightness: -30%);	
		min-height: auto;
		}
		
	.lower2 {
		font-size: 0.8em;
		padding: 20px 0;
		border-top: 1px dotted scale-color($text-color, $lightness: -30%);
		min-height: auto;
		}	

	.center{
		text-align: center;
	}	
	

}

@media only screen and (min-width: 40.063em) {

.footer {
  .segment:nth-child(n+2) {
    border-left: 1px solid scale-color($text-color, $lightness: -30%);
    min-height: 300px;
  }

 }
}

.footer-links {
  list-style-type: none;
  li {
    margin-top: 1.5em;
  }
}

.copyright {
    margin-top: 3rem;
	font-size: 1.25em;
}

.footer-left{
    border-right: 1px solid scale-color($text-color, $lightness: -30%);
}

.fieldset-spacer{
	margin-top: 2rem;
}

.surround{
	padding: 50px 0;
}

.interact{
	a{
		padding: 0.85em 1em;
		text-decoration:underline;
	}
	
}

.graybg{
	padding-bottom: 2em;
}

.b2li{
	a{
		text-decoration:underline;
	}
}

.progress-meter-text{
	font-size:1em;
}

//SMALL 
	
@media screen and (max-width: 40em) {
	.hero {
		height: 430px;
		background: url(../img/hero-small-girl.png) no-repeat;
		top: 0;
		display: block;
			-webkit-background-size: contain;
			-moz-background-size: contain;
			background-size: contain;	
			background-position: center bottom;
	}
	
	.hero2 {
		//height: 430px;
		background-color: $white;
		top: 0;
		display: block;
			-webkit-background-size: contain;
			-moz-background-size: contain;
			background-size: contain;	
			background-position: center bottom;
			
		.hero-content{
			text-align: center;
		}				
	}
	
	.hero-cvc {
		//height: 430px;
		background-color: $white;
		top: 0;
		display: block;
			-webkit-background-size: contain;
			-moz-background-size: contain;
			background-size: contain;	
			background-position: center bottom;
			
		.hero-content{
			text-align: center;
		}				
	}	

		.hero-c2m {
		//height: 430px;
		background-color: $white;
		top: 0;
		display: block;
			-webkit-background-size: contain;
			-moz-background-size: contain;
			background-size: contain;	
			background-position: center bottom;
			
		.hero-content{
			text-align: center;
		}				
	}

	.hero3 {
		//height: 430px;
		background-color: $white;
		top: 0;
		display: block;
			-webkit-background-size: contain;
			-moz-background-size: contain;
			background-size: contain;	
			background-position: center bottom;
			
			
			
		.headline {
		text-align: center;
    position: relative;
    padding-top: 20px;

		}		
	}	
  
  	.headline {
		padding-top: 15%;
		position: relative;
	
	}
	
	.sub-hero{
		margin-top: 100px;
	}
	
	.services{
		border-bottom: 0px solid rgba(105,105,105,.5);
	}
	
	.service-component {
		margin-top: 50px;
		padding-bottom: 50px;
		// border-bottom: 1px solid rgba(105,105,105,.5);
	}
	
	.service-image {
		margin-top: 0px;
		//padding-bottom: 50px;

	}
	.hidden-small {
		display: none;
	}
	
	.footer {
		padding: 0;
		text-align: left;
		
		.ul {
			margin-left: 0;
			}

		.footer-right{
			padding:32px 0;
			//border-top: 1px solid scale-color($text-color, $lightness: -50%);
		}
			
		}
	
	.page-header{
		padding: 20px 0;
		}	
		
	.left-border{
    border-left: none;
	}	
	
	.left-border2{
    border-left: none;
		padding:0;
	}

	.separator2 {
		text-align: center;
		margin: 1.25rem auto;
	}
	
	.separator-alert {
		text-align: center;
		margin: 1.25rem auto;
	}	

	.surround{
		padding: 0;
	}

	.service-box {
		padding-top: 20px;
		margin-right: 0.925rem;
		margin-left: 0.925rem;
	}
	

	
	.graybg{
		background-color: #fbfbfb;
	}
	
	.btm-space{
		margin-bottom: 2em;
		h5 {
			margin-bottom: 2em;	
		}
}

	.paypal-reg-img {
			padding-top: 1em;
	}

	.service-grp-heading{
		text-align:center; 
		padding-top:50px
	}		
}


//MEDIUM

@media screen and (min-width: 40.063em) {
	.hero { 
		height: 450px;
		top: 20%;		
		background: url(../img/hero-large-girl2.png) no-repeat;
		display: block;
			-webkit-background-size: contain;
			-moz-background-size: contain;
			background-size: contain;	
			background-position: center bottom;
  }
	
	.hero2 { 
		//height: 450px;
		top: 20%;		
		//background: url(../img/paypal-hero-large.png) no-repeat;
		display: block;
			-webkit-background-size: contain;
			-moz-background-size: contain;
			background-size: contain;	
			background-position: center bottom;
			
		.hero-content{
			text-align: left;
		}			
  }
	
	.hero-cvc { 
		//height: 450px;
		top: 20%;		
		//background: url(../img/paypal-hero-large.png) no-repeat;
		display: block;
			-webkit-background-size: contain;
			-moz-background-size: contain;
			background-size: contain;	
			background-position: center bottom;
			
		.hero-content{
			text-align: left;
		}			
  }

	.hero3 { 
		height: auto;
		margin-bottom:2em;
		top: 20%;		
		display: block;
			-webkit-background-size: contain;
			-moz-background-size: contain;
			background-size: contain;	
			background-position: center bottom;
			
		.headline {
		text-align: left;
    position: relative;
    padding-top: 50px;
		padding-left: 50px;		
		}
  }	
  
  
  	.hidden-medium {
		display: none;
	}
	
	.top-bar-left{
	width: 10% !important;
	padding-left: 0;
}

	.top-bar-right {
    width: auto !important;
}	
	
	.headline {
    position: relative;
    top: 10%;	
}

	.separator2 {
		text-align: left;
		margin: 1.25rem 0;
	}
	
	.paypal-reg-img {
    padding-top: 7em;
	}
}


//LARGE	
	
@media screen and (min-width: 64.063em) {

	.sub-hero {
		display: none;
	}
	
	.hero { 
		height: 700px;  
		background: url(../img/hero-large-girl2.png) no-repeat;
		display: block;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			background-size: cover;
		background-position: center bottom;	
	}
	
	.hero-mat { 
		height: 600px;  
		background: url(../img/hero-mat-large2.png) no-repeat;
		display: block;
			-webkit-background-size: 1000px;
			-moz-background-size: 1000px;
			background-size: 1000px;
		background-position: center bottom;
		
		.headline{
			padding-top: 6em 0;
		}
	}

	.hero-about { 
		height: 600px;  
		background: url(../img/about-hero.png) no-repeat;
		display: block;
			-webkit-background-size: 1000px;
			-moz-background-size: 1000px;
			background-size: 1000px;
		background-position: center bottom;
		
		.headline{
			padding-top: 6em 0;
		}
	}	
	
	.hero2 { 
		height: auto;  
		background: url(../img/paypal-landing-hero.png) no-repeat;
		display: block;
			background-size: 400px;
		background-position: right bottom;
		
		.hero-content{
			text-align: left;
		}
	}
	
	.hero-cvc { 
		height: auto;  
		background: url(../img/cvc-landing-hero.png) no-repeat;
		display: block;
			background-size: 400px;
		background-position: right bottom;
		
		.hero-content{
			text-align: left;
		}
	}
	
		.hero-c2m { 
		height: auto;  
		background: url(../img/cards2mpesa.png) no-repeat;
		display: block;
			background-size: 400px;
		background-position: right bottom;
		
		.hero-content{
			text-align: left;
		}
		
		p{
			margin-bottom: 0;
		}
	}
	
	.hero-404 { 
		height: 600px;  
		background: url(../img/paypal-landing-hero.png) no-repeat;
		display: block;
			background-size: 400px;
		background-position: right bottom;
		
		.hero-content{
			text-align: left;
		}
	}	
	
	.hero-mp { 
	height: auto;  
	//background: url(../img/mp-hero-bg.png) no-repeat;
	display: block;
		background-size: 400px;
	background-position: left bottom;
	
	.hero-content{
		text-align: left;
	}
}

	.hero3 { 
		height: auto;  
		background-color: $white;
		display: block;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			background-size: cover;
		background-position: center bottom;	
	}	
	
		.services-hero { 
		//height: 700px;  
		//background: url(../img/hero-large.png) no-repeat;
		display: block;
			-webkit-background-size: cover;
			-moz-background-size: cover;
			background-size: cover;
	}

	.top-bar-left{
		width: 50% !important;
		padding-left: 20%;
	}	

}

//PREVENT FOUC

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint(medium) {
    .title-bar {
      display: none;
    }
  }}
	
	
	
//BUTTONS

.button-group {
  list-style: none;
  margin: 0;
  left: 0; }
  .button-group:before, .button-group:after {
    content: " ";
    display: table; }
  .button-group:after {
    clear: both; }
  .button-group.even-2 li {
    display: inline-block;
    margin: 0 1px;
    width: 50%; }
    .button-group.even-2 li > button, .button-group.even-2 li .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group.even-2 li:first-child button, .button-group.even-2 li:first-child .button {
      border-left: 0; }
    .button-group.even-2 li button, .button-group.even-2 li .button {
      width: 100%; }
  .button-group.even-3 li {
    display: inline-block;
    margin: 0 1px;
    width: 33.33333%; }
    .button-group.even-3 li > button, .button-group.even-3 li .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group.even-3 li:first-child button, .button-group.even-3 li:first-child .button {
      border-left: 0; }
    .button-group.even-3 li button, .button-group.even-3 li .button {
      width: 100%; }
  .button-group.even-4 li {
    display: inline-block;
    margin: 0 1px;
    width: 25%; }
    .button-group.even-4 li > button, .button-group.even-4 li .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group.even-4 li:first-child button, .button-group.even-4 li:first-child .button {
      border-left: 0; }
    .button-group.even-4 li button, .button-group.even-4 li .button {
      width: 100%; }
  .button-group.even-5 li {
    display: inline-block;
    margin: 0 1px;
    width: 20%; }
    .button-group.even-5 li > button, .button-group.even-5 li .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group.even-5 li:first-child button, .button-group.even-5 li:first-child .button {
      border-left: 0; }
    .button-group.even-5 li button, .button-group.even-5 li .button {
      width: 100%; }
  .button-group.even-6 li {
    display: inline-block;
    margin: 0 1px;
    width: 16.66667%; }
    .button-group.even-6 li > button, .button-group.even-6 li .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group.even-6 li:first-child button, .button-group.even-6 li:first-child .button {
      border-left: 0; }
    .button-group.even-6 li button, .button-group.even-6 li .button {
      width: 100%; }
  .button-group.even-7 li {
    display: inline-block;
    margin: 0 1px;
    width: 14.28571%; }
    .button-group.even-7 li > button, .button-group.even-7 li .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group.even-7 li:first-child button, .button-group.even-7 li:first-child .button {
      border-left: 0; }
    .button-group.even-7 li button, .button-group.even-7 li .button {
      width: 100%; }
  .button-group.even-8 li {
    display: inline-block;
    margin: 0 1px;
    width: 12.5%; }
    .button-group.even-8 li > button, .button-group.even-8 li .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group.even-8 li:first-child button, .button-group.even-8 li:first-child .button {
      border-left: 0; }
    .button-group.even-8 li button, .button-group.even-8 li .button {
      width: 100%; }
  .button-group > li {
    display: inline-block;
    margin: 0 1px; }
    .button-group > li > button, .button-group > li .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group > li:first-child button, .button-group > li:first-child .button {
      border-left: 0; }
  .button-group.stack > li {
    display: block;
    margin: 0;
    float: none; }
    .button-group.stack > li > button, .button-group.stack > li .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group.stack > li:first-child button, .button-group.stack > li:first-child .button {
      border-left: 0; }
    .button-group.stack > li > button, .button-group.stack > li .button {
      border-color: rgba(255, 255, 255, 0.5);
      border-left-width: 0;
      border-top: 1px solid;
      display: block;
      margin: 0; }
    .button-group.stack > li > button {
      width: 100%; }
    .button-group.stack > li:first-child button, .button-group.stack > li:first-child .button {
      border-top: 0; }
  .button-group.stack-for-small > li {
    display: inline-block;
    margin: 0 1px; }
    .button-group.stack-for-small > li > button, .button-group.stack-for-small > li .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group.stack-for-small > li:first-child button, .button-group.stack-for-small > li:first-child .button {
      border-left: 0; }
    @media only screen and (max-width: 40em) {
      .button-group.stack-for-small > li {
        display: block;
        margin: 0;
        width: 100%; }
        .button-group.stack-for-small > li > button, .button-group.stack-for-small > li .button {
          border-left: 1px solid;
          border-color: rgba(255, 255, 255, 0.5); }
        .button-group.stack-for-small > li:first-child button, .button-group.stack-for-small > li:first-child .button {
          border-left: 0; }
        .button-group.stack-for-small > li > button, .button-group.stack-for-small > li .button {
          border-color: rgba(255, 255, 255, 0.5);
          border-left-width: 0;
          border-top: 1px solid;
          display: block;
          margin: 0; }
        .button-group.stack-for-small > li > button {
          width: 100%; }
        .button-group.stack-for-small > li:first-child button, .button-group.stack-for-small > li:first-child .button {
          border-top: 0; } }
  .button-group.radius > * {
    display: inline-block;
    margin: 0 1px; }
    .button-group.radius > * > button, .button-group.radius > * .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group.radius > *:first-child button, .button-group.radius > *:first-child .button {
      border-left: 0; }
    .button-group.radius > *,
    .button-group.radius > * > a,
    .button-group.radius > * > button,
    .button-group.radius > * > .button {
      border-radius: 0; }
    .button-group.radius > *:first-child, .button-group.radius > *:first-child > a, .button-group.radius > *:first-child > button, .button-group.radius > *:first-child > .button {
      -webkit-border-bottom-left-radius: 3px;
      -webkit-border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px; }
    .button-group.radius > *:last-child, .button-group.radius > *:last-child > a, .button-group.radius > *:last-child > button, .button-group.radius > *:last-child > .button {
      -webkit-border-bottom-right-radius: 3px;
      -webkit-border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px; }
  .button-group.radius.stack > * {
    display: block;
    margin: 0; }
    .button-group.radius.stack > * > button, .button-group.radius.stack > * .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group.radius.stack > *:first-child button, .button-group.radius.stack > *:first-child .button {
      border-left: 0; }
    .button-group.radius.stack > * > button, .button-group.radius.stack > * .button {
      border-color: rgba(255, 255, 255, 0.5);
      border-left-width: 0;
      border-top: 1px solid;
      display: block;
      margin: 0; }
    .button-group.radius.stack > * > button {
      width: 100%; }
    .button-group.radius.stack > *:first-child button, .button-group.radius.stack > *:first-child .button {
      border-top: 0; }
    .button-group.radius.stack > *,
    .button-group.radius.stack > * > a,
    .button-group.radius.stack > * > button,
    .button-group.radius.stack > * > .button {
      border-radius: 0; }
    .button-group.radius.stack > *:first-child, .button-group.radius.stack > *:first-child > a, .button-group.radius.stack > *:first-child > button, .button-group.radius.stack > *:first-child > .button {
      -webkit-top-left-radius: 3px;
      -webkit-top-right-radius: 3px;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px; }
    .button-group.radius.stack > *:last-child, .button-group.radius.stack > *:last-child > a, .button-group.radius.stack > *:last-child > button, .button-group.radius.stack > *:last-child > .button {
      -webkit-bottom-left-radius: 3px;
      -webkit-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px; }
  @media only screen and (min-width: 40.0625em) {
    .button-group.radius.stack-for-small > * {
      display: inline-block;
      margin: 0 1px; }
      .button-group.radius.stack-for-small > * > button, .button-group.radius.stack-for-small > * .button {
        border-left: 1px solid;
        border-color: rgba(255, 255, 255, 0.5); }
      .button-group.radius.stack-for-small > *:first-child button, .button-group.radius.stack-for-small > *:first-child .button {
        border-left: 0; }
      .button-group.radius.stack-for-small > *,
      .button-group.radius.stack-for-small > * > a,
      .button-group.radius.stack-for-small > * > button,
      .button-group.radius.stack-for-small > * > .button {
        border-radius: 0; }
      .button-group.radius.stack-for-small > *:first-child, .button-group.radius.stack-for-small > *:first-child > a, .button-group.radius.stack-for-small > *:first-child > button, .button-group.radius.stack-for-small > *:first-child > .button {
        -webkit-border-bottom-left-radius: 3px;
        -webkit-border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px; }
      .button-group.radius.stack-for-small > *:last-child, .button-group.radius.stack-for-small > *:last-child > a, .button-group.radius.stack-for-small > *:last-child > button, .button-group.radius.stack-for-small > *:last-child > .button {
        -webkit-border-bottom-right-radius: 3px;
        -webkit-border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px; } }
  @media only screen and (max-width: 40em) {
    .button-group.radius.stack-for-small > * {
      display: block;
      margin: 0; }
      .button-group.radius.stack-for-small > * > button, .button-group.radius.stack-for-small > * .button {
        border-left: 1px solid;
        border-color: rgba(255, 255, 255, 0.5); }
      .button-group.radius.stack-for-small > *:first-child button, .button-group.radius.stack-for-small > *:first-child .button {
        border-left: 0; }
      .button-group.radius.stack-for-small > * > button, .button-group.radius.stack-for-small > * .button {
        border-color: rgba(255, 255, 255, 0.5);
        border-left-width: 0;
        border-top: 1px solid;
        display: block;
        margin: 0; }
      .button-group.radius.stack-for-small > * > button {
        width: 100%; }
      .button-group.radius.stack-for-small > *:first-child button, .button-group.radius.stack-for-small > *:first-child .button {
        border-top: 0; }
      .button-group.radius.stack-for-small > *,
      .button-group.radius.stack-for-small > * > a,
      .button-group.radius.stack-for-small > * > button,
      .button-group.radius.stack-for-small > * > .button {
        border-radius: 0; }
      .button-group.radius.stack-for-small > *:first-child, .button-group.radius.stack-for-small > *:first-child > a, .button-group.radius.stack-for-small > *:first-child > button, .button-group.radius.stack-for-small > *:first-child > .button {
        -webkit-top-left-radius: 3px;
        -webkit-top-right-radius: 3px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px; }
      .button-group.radius.stack-for-small > *:last-child, .button-group.radius.stack-for-small > *:last-child > a, .button-group.radius.stack-for-small > *:last-child > button, .button-group.radius.stack-for-small > *:last-child > .button {
        -webkit-bottom-left-radius: 3px;
        -webkit-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px; } }
  .button-group.round > * {
    display: inline-block;
    margin: 0 1px; }
    .button-group.round > * > button, .button-group.round > * .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group.round > *:first-child button, .button-group.round > *:first-child .button {
      border-left: 0; }
    .button-group.round > *,
    .button-group.round > * > a,
    .button-group.round > * > button,
    .button-group.round > * > .button {
      border-radius: 0; }
    .button-group.round > *:first-child, .button-group.round > *:first-child > a, .button-group.round > *:first-child > button, .button-group.round > *:first-child > .button {
      -webkit-border-bottom-left-radius: 1000px;
      -webkit-border-top-left-radius: 1000px;
      border-bottom-left-radius: 1000px;
      border-top-left-radius: 1000px; }
    .button-group.round > *:last-child, .button-group.round > *:last-child > a, .button-group.round > *:last-child > button, .button-group.round > *:last-child > .button {
      -webkit-border-bottom-right-radius: 1000px;
      -webkit-border-top-right-radius: 1000px;
      border-bottom-right-radius: 1000px;
      border-top-right-radius: 1000px; }
  .button-group.round.stack > * {
    display: block;
    margin: 0; }
    .button-group.round.stack > * > button, .button-group.round.stack > * .button {
      border-left: 1px solid;
      border-color: rgba(255, 255, 255, 0.5); }
    .button-group.round.stack > *:first-child button, .button-group.round.stack > *:first-child .button {
      border-left: 0; }
    .button-group.round.stack > * > button, .button-group.round.stack > * .button {
      border-color: rgba(255, 255, 255, 0.5);
      border-left-width: 0;
      border-top: 1px solid;
      display: block;
      margin: 0; }
    .button-group.round.stack > * > button {
      width: 100%; }
    .button-group.round.stack > *:first-child button, .button-group.round.stack > *:first-child .button {
      border-top: 0; }
    .button-group.round.stack > *,
    .button-group.round.stack > * > a,
    .button-group.round.stack > * > button,
    .button-group.round.stack > * > .button {
      border-radius: 0; }
    .button-group.round.stack > *:first-child, .button-group.round.stack > *:first-child > a, .button-group.round.stack > *:first-child > button, .button-group.round.stack > *:first-child > .button {
      -webkit-top-left-radius: 1rem;
      -webkit-top-right-radius: 1rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem; }
    .button-group.round.stack > *:last-child, .button-group.round.stack > *:last-child > a, .button-group.round.stack > *:last-child > button, .button-group.round.stack > *:last-child > .button {
      -webkit-bottom-left-radius: 1rem;
      -webkit-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem; }
  @media only screen and (min-width: 40.0625em) {
    .button-group.round.stack-for-small > * {
      display: inline-block;
      margin: 0 1px; }
      .button-group.round.stack-for-small > * > button, .button-group.round.stack-for-small > * .button {
        border-left: 1px solid;
        border-color: rgba(255, 255, 255, 0.5); }
      .button-group.round.stack-for-small > *:first-child button, .button-group.round.stack-for-small > *:first-child .button {
        border-left: 0; }
      .button-group.round.stack-for-small > *,
      .button-group.round.stack-for-small > * > a,
      .button-group.round.stack-for-small > * > button,
      .button-group.round.stack-for-small > * > .button {
        border-radius: 0; }
      .button-group.round.stack-for-small > *:first-child, .button-group.round.stack-for-small > *:first-child > a, .button-group.round.stack-for-small > *:first-child > button, .button-group.round.stack-for-small > *:first-child > .button {
        -webkit-border-bottom-left-radius: 1000px;
        -webkit-border-top-left-radius: 1000px;
        border-bottom-left-radius: 1000px;
        border-top-left-radius: 1000px; }
      .button-group.round.stack-for-small > *:last-child, .button-group.round.stack-for-small > *:last-child > a, .button-group.round.stack-for-small > *:last-child > button, .button-group.round.stack-for-small > *:last-child > .button {
        -webkit-border-bottom-right-radius: 1000px;
        -webkit-border-top-right-radius: 1000px;
        border-bottom-right-radius: 1000px;
        border-top-right-radius: 1000px; } }
  @media only screen and (max-width: 40em) {
    .button-group.round.stack-for-small > * {
      display: block;
      margin: 0; }
      .button-group.round.stack-for-small > * > button, .button-group.round.stack-for-small > * .button {
        border-left: 1px solid;
        border-color: rgba(255, 255, 255, 0.5); }
      .button-group.round.stack-for-small > *:first-child button, .button-group.round.stack-for-small > *:first-child .button {
        border-left: 0; }
      .button-group.round.stack-for-small > * > button, .button-group.round.stack-for-small > * .button {
        border-color: rgba(255, 255, 255, 0.5);
        border-left-width: 0;
        border-top: 1px solid;
        display: block;
        margin: 0; }
      .button-group.round.stack-for-small > * > button {
        width: 100%; }
      .button-group.round.stack-for-small > *:first-child button, .button-group.round.stack-for-small > *:first-child .button {
        border-top: 0; }
      .button-group.round.stack-for-small > *,
      .button-group.round.stack-for-small > * > a,
      .button-group.round.stack-for-small > * > button,
      .button-group.round.stack-for-small > * > .button {
        border-radius: 0; }
      .button-group.round.stack-for-small > *:first-child, .button-group.round.stack-for-small > *:first-child > a, .button-group.round.stack-for-small > *:first-child > button, .button-group.round.stack-for-small > *:first-child > .button {
        -webkit-top-left-radius: 1rem;
        -webkit-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem; }
      .button-group.round.stack-for-small > *:last-child, .button-group.round.stack-for-small > *:last-child > a, .button-group.round.stack-for-small > *:last-child > button, .button-group.round.stack-for-small > *:last-child > .button {
        -webkit-bottom-left-radius: 1rem;
        -webkit-bottom-right-radius: 1rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem; } }

.button-bar:before, .button-bar:after {
  content: " ";
  display: table; }
.button-bar:after {
  clear: both; }
.button-bar .button-group {
  float: left;
  margin-right: 0.625rem; }
  .button-bar .button-group div {
    overflow: hidden;}
		
.button-green{
	color: $white;
	background-color: $primary-color;
	&:hover{
		background-color: scale-color($primary-color, $lightness: -15%);
	}
}

.button-white{
	color: $primary-color;
	background-color: $white;
	&:hover{
		background-color: $white;
		color: scale-color($primary-color, $lightness: -15%);
	}
	&:after{
		background-color: $white;
		color: scale-color($primary-color, $lightness: -15%);
	}	
}		
	
#widemenu {
		//box-shadow: 1px 0 10px rgba(10, 10, 10, 0.5); 
		position: relative;
		border-bottom: 1px solid #b3b3b3;
}

.title-bar {
		//box-shadow: 1px 0 10px rgba(10, 10, 10, 0.5); 
		position: relative;
		background-color: #FBFBFB !important;
		border-bottom: 1px solid #b3b3b3;		
		
		.active > a {
    color: $primary-color;
    background: #fff;		
}
}




.top-bar {
	background-color: #FBFBFB !important;
	padding-bottom: 0;
	ul{
		background-color: #FBFBFB
	}
	
	
	.menu-text{
		background-color: #FBFBFB;
		padding:1.3rem 1rem;
		font-weight: normal;
	}

	.has-submenu{
		background-color: #fff;
	}

	li{
		a{
			padding:1.3rem 1rem;
			background-color: #FBFBFB;			
		}
		
		}		
		

	.button {
		background-color: #FBFBFB;
		color: $primary-color;
		border-bottom: 3px solid #FBFBFB;		
		//padding-bottom:3px;
		//margin-bottom: 3px;
		&:hover {
		background-color: #FBFBFB;
		color: $secondary-color;
		border-bottom: 3px solid $secondary-color;
		}
		&:active {
		background-color: #FBFBFB;
		color: $secondary-color;
		border-bottom: 3px solid $secondary-color;		
		
	}
	}

}

.top-bar .active > a {
    color: $secondary-color;
    background: #fff;
		border-bottom: 3px solid $secondary-color;		
}



.breadcrumbs {
    margin: 1rem;
}
#offCanvasRight {
	min-height: 600px;
}


// .off-canvas.position-right { 
	// position:fixed; 
	// right:-250px; 
	// top:0; z-index:1; 
	// transition:0.5s ease; 
	// }
	
// .is-open-right .off-canvas.position-right {
	// right:-0px; 
	// }
// .admin-bar .off-canvas.position-right { 
	// top:32px; 
	// }
	
// .off-canvas { 
	// background-color: transparent; 
	// }
	
// .is-open-right { 
	// -webkit-transform: initial; 
	// transform: initial; 
	// margin-left: -250px; 
	// }
	
// .off-canvas-wrapper-inner { 
	// transition: margin 0.5s ease; 
	// }
	
// .sticky.is-stuck { 
	// left:0px !important; 
	// transition:0.5s; 
	// }
	
// .is-open-right .sticky.is-stuck { 
	// left:-250px !important;
	// }


.dropdown-pane {
    background-color: #7dbd05;
    //z-index: -1;
	}

.more-info-panel{
	border-radius: 1em;
	border: 1px solid $primary-color;
	padding: 1.5em;
	margin-bottom: 2em;
	background-color: $white;
	text-align:left;
	//color: $white;
}

.warning-info-panel{
	border-radius: 1em;
	border: 1px dashed $secondary-color;
	padding: 1em;
	margin-bottom: 2em;
	background-color: $white;
	
	#warningHeader{
		color: $secondary-color;
	}

}	


#warningHeader{
	padding-top: 1em;
}


	
//help page

//form

	.form-control:focus{
			border:none;
			border-bottom:solid #7dbd05 3px;
			-webkit-box-shadow: none;
			box-shadow: none;
	}

	input[type=text] {
			border:0px;
			box-shadow:none;
			border-bottom:solid #d8d8d8 1px;
			border-radius:0px;
			margin-bottom:32px;
	}
	
	input[type="tel"] {
			border:0px;
			box-shadow:none;
			border-bottom:solid #d8d8d8 1px;
			border-radius:0px;
			margin-bottom:32px;
	}

	textarea {
			border:0px;
			box-shadow:none;
			border-bottom:solid #d8d8d8 1px;
			border-radius:0px;
			margin-bottom:32px;
	}	

	input[type="text"]:focus {
				border:0px;
			box-shadow:none;
			border-bottom:solid #7dbd05 3px;
			border-radius:0px;
	}

	select.custom{
			border:0px;
			box-shadow:none;
			border-bottom:solid #d8d8d8 1px;
			border-radius:0px;
			margin-bottom:32px;
	}
	select.custom:focus {
				border:0px;
			box-shadow:none;
			border-bottom:solid #7dbd05 3px;
			border-radius:0px;
	}
	
	.hero2{
	
		.form-control:focus{
			border:none;
			border-bottom:solid #7dbd05 3px;
			-webkit-box-shadow: none;
			box-shadow: none;
	}

		input[type=text] {
			border:solid #d8d8d8 2px;
			box-shadow:none;
			border-radius:1000px;
			margin-bottom:32px;
	}
	
		input[type="text"]:focus {
			box-shadow:none;
			border:solid #7dbd05 2px;
			border-radius:1000px;
	}
	}
	
		.hero-cvc{
	
		.form-control:focus{
			border:none;
			border-bottom:solid #7dbd05 3px;
			-webkit-box-shadow: none;
			box-shadow: none;
	}

		input[type=text] {
			border:solid #d8d8d8 2px;
			box-shadow:none;
			border-radius:1000px;
			margin-bottom:32px;
	}
	
		input[type="text"]:focus {
			box-shadow:none;
			border:solid #7dbd05 2px;
			border-radius:1000px;
	}
	}
	
	.hero-c2m{
	
		.form-control:focus{
			border:none;
			border-bottom:solid #7dbd05 3px;
			-webkit-box-shadow: none;
			box-shadow: none;
	}

		input[type=text] {
			border:solid #d8d8d8 2px;
			box-shadow:none;
			border-radius:1000px;
			margin-bottom:16px;
	}
	
		input[type=tel] {
			border:solid #d8d8d8 2px;
			box-shadow:none;
			border-radius:1000px;
			margin-bottom:32px;
			width: 100%;
	}	
	
		input[type="text"]:focus {
			box-shadow:none;
			border:solid #7dbd05 2px;
			border-radius:1000px;
	}
	
		input[type="tel"]:focus {
			box-shadow:none;
			border:solid #7dbd05 2px;
			border-radius:1000px;
	}	
	}
	
	
	.hero-mp{
	
		.form-control:focus{
			border:none;
			border-bottom:solid #7dbd05 3px;
			-webkit-box-shadow: none;
			box-shadow: none;
	}

		input[type=text] {
			border:solid #d8d8d8 2px;
			box-shadow:none;
			border-radius:1000px;
			margin-bottom:32px;
	}
	
		input[type="text"]:focus {
			box-shadow:none;
			border:solid #7dbd05 2px;
			border-radius:1000px;
	}
	}	

	.viz-fields{
	
		.form-control {
			border:solid #d8d8d8 2px;
			box-shadow:none;
			border-radius:1000px;
			margin-bottom:32px;
	}
	
		.form-control:focus {
			box-shadow:none;
			border:solid #7dbd05 2px;
			border-radius:1000px;
	}	

		input[type=text] {
			border:solid #d8d8d8 2px;
			box-shadow:none;
			border-radius:1000px;
			margin-bottom:32px;
	}
	
		input[type="text"]:focus {
			box-shadow:none;
			border:solid #7dbd05 2px;
			border-radius:1000px;
	}
	}
	
	.cvc{
	
		.cvc-form{
			margin-top:16px;
		
		}
	
		.viz-fields{
		
				.form-control:focus{
				border:none;
				border-bottom:solid #7dbd05 3px;
				-webkit-box-shadow: none;
				box-shadow: none;
		}

			input[type=text]{
				border:solid #d8d8d8 2px;
				box-shadow:none;
				border-radius:1000px;
				margin:16px 0;
		}
		
			input[type="text"]:focus {
				box-shadow:none;
				border:solid #7dbd05 2px;
				border-radius:1000px;
		}
		
			select{
				border:solid #d8d8d8 2px;
				box-shadow:none;
				border-radius:1000px;
				margin:16px 0;
		}
		
			select:focus {
				box-shadow:none;
				border:solid #7dbd05 2px;
				border-radius:1000px;
		}		
		}	
	
	}
	
	.viz-fields-search{
	
			.form-control:focus{
			border:none;
			border-bottom:solid #7dbd05 3px;
			-webkit-box-shadow: none;
			box-shadow: none;
	}

		input[type=text] {
			border:solid #fbae17 2px;
			box-shadow:none;
			border-radius:1000px;
			margin-top:32px;
			margin-bottom:32px;
			background-color: #ffffff;
	}
	
		input[type="text"]:focus {
			box-shadow:none;
			border:solid #ffffff 2px;
			border-radius:1000px;
	}
	}	
	
	.form-custom{
	
		input[type=text] {
			border:solid #d8d8d8 2px;
			box-shadow:none;
        -webkit-border-bottom-left-radius: 1000px !important;
        -webkit-border-top-left-radius: 1000px !important;
        border-bottom-left-radius: 1000px !important;
        border-top-left-radius: 1000px !important;
				border-radius: 0;
			margin-bottom:32px;
	}
	
		input[type="text"]:focus {
			box-shadow:none;
			border:solid #7dbd05 2px;
        -webkit-border-bottom-left-radius: 1000px !important;
        -webkit-border-top-left-radius: 1000px !important;
        border-bottom-left-radius: 1000px !important;
        border-top-left-radius: 1000px !important;			
			border-radius:0;
	}
	
		select.custom{
			border:0px;
			box-shadow:none;
			border-left: none;
			border:solid #d8d8d8 2px;
        -webkit-border-bottom-right-radius: 1000px;
        -webkit-border-top-right-radius: 1000px;
        border-bottom-right-radius: 1000px;
        border-top-right-radius: 1000px;
			margin-bottom:32px;
	}
	select.custom:focus {
			border:solid #7dbd05 2px;
			
	}
	
	@media screen and (max-width: 40em){
	
		input[type=text] {
			border:solid #d8d8d8 2px;
			box-shadow:none;
			border-radius:1000px;
        -webkit-border-bottom-left-radius: 0;
        -webkit-border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;			
			margin-bottom:32px;
	}
	
		input[type="text"]:focus {
			box-shadow:none;
			border:solid #7dbd05 2px;
			border-radius:1000px;
	
	}
	
		select.custom{
			box-shadow:none;
			border:solid #d8d8d8 2px;
			border-radius:1000px;
			margin-bottom:32px;
	}
	}
	
	}

	
	hr{
		max-width:100%;
	}
	
	
.step-num{
	border: 3px solid $medium-gray;
	padding: 0.25em 0.25em;
	border-radius: 1000px;
	height: 50px;
	width: 50px;
	color: $primary-color;
	text-align: center;
	}

.make-step{
	padding: 1em 0;	
}


.alert{
	color: $alert-color;
}

.tabs-title{	
	a{
		font-size: 1em;
	}
	
	a: focus {
		color: $secondary-color;
		background-color: $white;
	}
}

//input group

.input-group { 

	.input-group-field {
			@extend %input-group-child;
			border-radius: 0;
			border-top-right-radius: 1000px;
			border-bottom-right-radius: 1000px;			
			border: 2px solid $light-gray;
			

			// scss-lint:disable ZeroUnit
			@if $global-flexbox {
				flex: 1 1 0px;
				width: auto;
				height: auto;
			}
			@else {
				height: 2.5em;
			}
		}
		
	input[type="text"]:focus {
		box-shadow:none;
		border:solid #7dbd05 2px;
			border-top-right-radius: 1000px;
			border-bottom-right-radius: 1000px;		
	}	
	
	
	.input-group-label {
		text-align: center;
		padding: 0 1rem;
		background: #e6e6e6;
		color: $dark-gray;
		border: 0;
		white-space: nowrap;
		width: 1%;
		height: 100%;
		
	}

	.button-white{
		@extend %input-group-child;
		color: $primary-color;
		background-color: $white;
		border: 2px solid $light-gray;
		height: 2.5em;
		&:hover{
			background-color: $white;
			color: scale-color($primary-color, $lightness: -15%);
		}
		&:after{
			background-color: $white;
			color: scale-color($primary-color, $lightness: -15%);
		}	
	}		


	:first-child {
		border-radius: 0 0 0 0;
		border-top-left-radius: 1000px;
		border-bottom-left-radius: 1000px;
	}

 :last-child > * {
		border-radius: 0 0 0 0;
		border-top-right-radius: 1000px;
		border-bottom-right-radius: 1000px;
	}
	

}

.help-search {

	.form-control:focus{
			border:none;
			border-bottom:solid #7dbd05 3px;
			-webkit-box-shadow: none;
			box-shadow: none;
	}

	input[type=text] {
			border:0px;
			box-shadow:none;
			//border-bottom:solid #d8d8d8 1px;
			border-radius:0px;
			//margin-bottom:32px;
	}
	
	input[type="tel"] {
			border:0px;
			box-shadow:none;
			border-bottom:solid #d8d8d8 1px;
			border-radius:0px;
			//margin-bottom:32px;
	}

	textarea {
			border:0px;
			box-shadow:none;
			border-bottom:solid #d8d8d8 1px;
			border-radius:0px;
			//margin-bottom:32px;
	}	

	input[type="text"]:focus {
			box-shadow:none;
			border:solid #7dbd05 2px;
			border-radius:0px;
	}
	

}


//ACCORDION


.accordion-title {
    display: block;
    padding: 2rem 1rem;
    line-height: 1;
    font-size: 1rem;
    color: #7dbd05;
    position: relative;
    border: 0;
		//border-bottom: 1px solid $light-gray;
		
}

:last-child:not(.is-active) > .accordion-title {
    border-radius: 0 0 0 0;
    border-bottom: 0;
}

.accordion-content {
    padding: 1rem;
    display: none;
    border: 0;
		//border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    background-color: #fff;
    color: $dark-gray;
}

.box-bottom {
		margin-bottom: 30px;
		margin-right: 0.925rem;
		margin-left: 0.925rem;
	}
	
.iti-flag {background-image: url("../img/flags.png");}	




.intl-tel-input {

    width: 100%;
		margin-bottom: 16px;
}


.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 .selected-flag {
    min-width: 100px !important;
		border-top-left-radius: 1000px;
		border-bottom-left-radius: 1000px;
}


.intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input, .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type="text"], .intl-tel-input.separate-dial-code.allow-dropdown.iti-sdc-4 input[type="tel"] {
    padding-left: 116px !important;
}

.button.hollow {
    border: 2px solid #7dbd05;
    color: #7dbd05;
}

.tabs-panel-p > .button {
    border: 2px solid #7dbd05;
    color: #7dbd05;
		min-width: 120px;
}

.button-orange.hollow {
    border: 2px solid #fbae17;
    color: #fbae17;
}

.shift-align{
	padding: 32px 0;
	@media screen and (max-width: 40em){
		text-align: center !important;
	}
	
	@media screen and (min-width: 40em){
		text-align: left !important;
	}

}


///tabs

.tabs-title > a:focus, .tabs-title > a[aria-selected="true"] {
    background: #fff;
    color: $secondary-color;
		font-weight:600;	
}

.tabs-panel {
    display: none;
    padding: 1rem 0;
}

 @media screen and (max-width: 40em){
 
	.tabs-title {
	
		float: none;
	
	a:focus, .tabs-title > a[aria-selected="true"] {
    background: #fff;
    color: $secondary-color;
	}
	
	}

	.tabs-panel {
    display: none;
    padding: 1rem 0;
	}
 
 }

//callout

.callout {
	a {
		color: $white;
		text-decoration: underline;
		
		&:hover {
			opacity: 0.8;
		}
	}
}

.light-line {
		border-bottom: 1px solid #D8D8D8 !important;
}


.thick-line {
		border-bottom: 3px solid #D8D8D8 !important;
		
}

// @media screen and (max-width: 40em) {
	// .hidden-small {
		// display: none;
	// }
// }